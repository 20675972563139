import React from 'react';
import { Link} from "gatsby"
import win_icon from "../../icons/header/windows.svg"
import zhifubao from "../../icons/header/zhifubao.svg"
import game from "../../icons/header/game.svg"
import youtube from "../../icons/header/youtube.svg"
import lantern_icon from "../../icons/header/lantern.svg"
import ss_icon from "../../icons/header/shadowsocks.svg"
import discount from "../../icons/header/discount.svg"
import qa from "../../icons/header/qa.svg"
import free from "../../icons/header/free.svg"

const DropdownLarge = ({base_url, dropdown}) =>{
  const leftUp = dropdown[0]
  const leftDown = dropdown[1]
  const middle = dropdown[2]
  const right = dropdown[3]

  const icons = [zhifubao,discount,youtube,game,qa]
  const rest_icons = [lantern_icon, ss_icon, win_icon,free]

  return(
    <div id="best" className="dropdown" >
      <div className="left">
        <div className="top">
          <h3>{leftUp.title}</h3>
          <p>{leftUp.p}</p>
          <p className="highlight"><Link to={base_url} >{leftUp.links[0].name}</Link></p>
          <br/>
        </div>
        <div className="bottom">
          <h3>{leftDown.title}</h3>
          {leftDown.links.map((link) => (
            <p className="highlight"><Link to={base_url+link.url} >{link.name}</Link></p>
          ))}
        </div>
      </div>
      <div className="right">
        <div className="columns">
          <h3>{middle.title}</h3>
          {middle.links.map((link)=>(
            <Link to={base_url + link.url} >
              <div className="icons">
                <img src={icons[link.icon_id]} alt={link.name} />
              </div>
              <div className="descriptions">
                <h5>{link.name}</h5>
                <p>{link.p}</p>
              </div>
          </Link>
          ))}
        </div>
        <div className="columns">
          <h3>{right.title}</h3>
          {right.links.map((link)=>(
            <Link to={base_url + link.url} >
              <div className="icons">
                <img src={rest_icons[link.icon_id]} alt={link.name} />
              </div>
              <div className="descriptions">
                <h5>{link.name}</h5>
                <p>{link.p}</p>
              </div>
          </Link>
          ))}
        </div>
      </div>
    </div>
  )
}

export default DropdownLarge