import React from "react"
import Header from "./nav/header"
import Footer from "./nav/footer"
import {useStaticQuery, graphql} from "gatsby"
import { Helmet } from "react-helmet"
import "../styles/main.sass"
import 'bootstrap/dist/css/bootstrap.min.css';
import favicon from "../icons/favicon.ico"
const Layout = ({ children, title }) => {
    const data = useStaticQuery(graphql`
        query{
            allDataJson {
                edges {
                    node {
                        helmet {
                            title
                            url
                            description
                            keywords
                        }
                    }
                }
            }
        }
    `)
    const head = data.allDataJson.edges.filter((edge)=> {return edge.node.helmet !== null})[0].node.helmet
    return(
        <div id="web">
            <Header />
            <div className="layout" >
                <Helmet>
                    <meta charSet="utf-8" />
                    <base target="_blank"></base>
                    {title && <title>{title}</title>}
                    <link rel="icon" href={favicon} />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
                    <meta name="description" content={head.description} />
                    <meta name="keywords" content={head.keywords} />
                    <meta http-equiv='content-language' content='zh-cn' />
                    <meta name="google-site-verification" content="rRoHanJi_eDLh3SX1iFmbXqQTyYdBpOGpdhe1O7Qsfc" />
                    <html lang="zh" />
                    <script type="text/javascript">
                        {`
                            (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                            })(window, document, "clarity", "script", "4dz4ro2aeg");
                        `}
                    </script>
                </Helmet>
                {children}
            </div>
            <Footer />
        </div>
    )
}

export default Layout
