import React from 'react';
import { Link} from "gatsby"
import coursera_icon from "../../icons/header/coursera.svg"
import edx_icon from "../../icons/header/edx.svg"
import netflix_icon from "../../icons/header/netflix.svg"

const DropdownTwoColumns =({base_url, dropdown}) =>{

    const icons = [edx_icon,coursera_icon,netflix_icon]

    return(
        <div id="share" className="dropdown" >
            {dropdown.map((column)=>(
                <div className="columns">
                    <h3>{column.title}</h3>
                    {column.links.map((link)=>(
                        <Link to={base_url + link.url} >
                            <div className="icons">
                                <img src={icons[link.icon_id]} alt={link.name} />
                            </div>
                            <div className="descriptions">
                                <h5>{link.name}</h5>
                                <p>{link.p}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            ))}
        </div>
    )
}

export default DropdownTwoColumns