import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from "gatsby"
import logo from "../../icons/logo.svg"
import search_icon from "../../icons/header/search.svg"
import DropdownLarge from './dropdownLarge';
import DropdownTwoColumns from './dropdownTwoColumns';
import ham from "../../icons/header/ham.svg"
import cross from "../../icons/header/cross.svg"

const Header= () => {
  const data = useStaticQuery(graphql`
    query {
      allDataJson {
        edges {
          node {
            header {
              navigations {
                url
                label
                has_dropdown
                dropdown {
                  p
                  title
                  links {
                    name
                    p
                    url
                    icon_id
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const navLinks = data.allDataJson.edges.filter((edge)=> {return edge.node.header !== null})[0].node.header.navigations
  const [menuState, setMenu] = useState(false)
  const [dropState, setDrop] = useState([false,false,false,false])

  const toggleMenu = index => e => {
    let newArr = [...dropState]; // copying the old datas array
    newArr[index] = !dropState[index]; // replace e.target.value with whatever you want to change it to
    setDrop(newArr)
  }

  return(
    <nav>
      <div id="navbar">
        <hr className={( menuState? "hidden": null)} />
        <div id="lg-menu">
          <div className="links container">
            <Link className="logo" to="/">
              <img loading="eager" src={logo} alt="logo 翻墙101" />
            </Link>
            <div id={"navlinks"}>
              {navLinks.map((navlink,idx)=>(
                <div className="navlink">
                  <Link to={navlink.url === "/status" ? "" : navlink.url} 
                    className={"link-menu "+(navlink.has_dropdown? "hasDropdown": "noDropdown")}
                    activeClassName="active"
                    partiallyActive={true}
                  >
                    {navlink.label}
                    {/* {(idx === 0) && <div className={"arrow-up hidden grey"}/>} */}
                    {(idx === 1 || idx === 3) && <div className={"arrow-up hidden"}/>}
                  </Link>
                  <div className="drop-content hidden">
                    { idx === 1 && <DropdownLarge base_url={navLinks[1].url} dropdown={navLinks[1].dropdown} /> } 
                    { idx === 3 && <DropdownTwoColumns base_url={navLinks[3].url} dropdown={navLinks[3].dropdown} /> }
                    {/* { idx === 3 && <DropdownSmall base_url={navLinks[3].url} dropdown={navLinks[3].dropdown} /> } */}
                  </div>
                </div>
              ))}
            </div>
            <div id="search" className="right-bar" ><img src={search_icon} alt="搜索按钮" /></div>
            <div id="ham" 
              className={"right-bar "+( menuState? "hidden": null) }
              onClick={()=>setMenu(true) }
              onKeyDown={()=>setMenu(true)}
              role = "button"
              tabIndex={0}
            ><img src={ham} alt="菜单"/></div>
          </div>
        </div>
        <div id="mobile" className={!menuState? "hidden": null} >
          <div id="menu">
            <div id="bar">
              <Link className="logo" to="/">
                <img src={logo} alt="logo 翻墙101" />
              </Link>
              <div id="cross" 
              onClick={()=>setMenu(false) }
              onKeyDown={()=>setMenu(false)}
              role = "button"
              tabIndex={0}
              ><img src={cross} alt="关闭菜单"/></div>
            </div>
            <div id="navlinks" className={(dropState.every((val, i)=> val === false)? "trans-before" : "trans-after remove-dom")}>
              {navLinks.map((navlink,idx)=>(
                <>
                  { navlink.has_dropdown && <div className={"navlink"}
                      onClick={toggleMenu(idx)}
                      onKeyDown={toggleMenu(idx)}
                      role = "button"
                      tabIndex={0}
                      ><span className="navlink-a">{navlink.label}</span><span> ➔ </span>
                    </div>
                  }
                  { !navlink.has_dropdown && <div className={"navlink"}
                      role = "button"
                      tabIndex={0}
                      ><Link to={navlink.url} className="navlink-a" >{navlink.label}</Link>
                    </div>
                  }
                </>
              ))}
            </div>
            <div id="menu-content" 
              className={(dropState.every((val, i)=> val === false)? "trans-before remove-dom" : "trans-after")} 
            >
              <button id="back" onClick={()=>setDrop([false,false,false,false])}>
                <p><span>⬅︎ </span> 回到上页</p>
              </button>
              <div id="dropdown" >
              {dropState[1] && <DropdownLarge base_url={navLinks[1].url} dropdown={navLinks[1].dropdown} />}
              {dropState[3] && <DropdownTwoColumns base_url={navLinks[3].url} dropdown={navLinks[3].dropdown} />}
              {/* {dropState[3] && <DropdownSmall base_url={navLinks[3].url} dropdown={navLinks[3].dropdown} />} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header