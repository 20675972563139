import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import logo_w from "../../icons/logo_w.svg"

const Footer = () => {
    const data = useStaticQuery(graphql`
      query {
        siteData: allDataJson {
            edges {
              node {
                footer {
                    about{
                        title
                        ps{
                            p
                            url
                        }
                    }
                    contact{
                        title
                        ps{
                            p
                            url
                        }
                    }
                    copyright
                }
              }
            }
          }
      }
    `)
    const content = data.siteData.edges.filter((edge)=> {return edge.node.footer !== null})[0].node.footer
    return(
        <div id="footer">
            <div  className="container" >
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div id="logo-wh">
                            <Link className="logo" to="/">
                                <img src={logo_w} alt="logo 翻墙101" /> 
                            </Link>
                        </div>
                        <div id="intro">
                            <p>我们致力于通过最专业科学的评测与大数据分析，提供最为可靠专业的翻墙建议</p>
                            <p>自从2018年以来，我们已经帮助无数的人找到了适合他们的VPN工具</p>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2 content">
                        <h2>{content.about.title}</h2>
                        <div className="lists">
                            {content.about.ps.map((e)=>(
                                <div className="item">
                                    <p className="p-content">
                                        <Link to={e.url}>{e.p}</Link>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div class="col-6 col-lg-3 content">
                        <h2>{content.contact.title}</h2>
                        <div className="lists">
                            {content.contact.ps.map((e)=>(
                                <div className="item">
                                   <p className="p-content">
                                        <a href={e.url}>{e.p}</a>
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div id="rights" class="row">
                    <p>{content.copyright}</p>
                </div>
            </div>
        </div>
    )
}
export default Footer